.project-info {
	box-sizing: content-box;
	width: calc(100% - var(--p-project) - 5rem);
	height: var(--h-project-info);
	padding: var(--p-project-half);
	transition: height 0.2s ease-in-out;
	background: white;
	cursor: pointer;
}

.project-info [data-project-info] {
	overflow: hidden;
	height: 100%;
	padding: 0;
}

.project-info--is-open .project-info--is-open {
	height: var(--h-project-info-open);
}

@media (orientation: landscape) {
	.project-info {
		width: calc(100% - var(--p-project));
		padding: var(--p-project) var(--p-project-half);
	}

	.project-info-top {
		width: var(--w-poster);
		margin: 0 auto;
	}

	.project-info--is-open .project-info {
		height: var(--h-project-info-open);
	}
}
