.menu--button {
	position: fixed;
	clip: rect(0,0,0,0);
}

.menu--button--label {
	position: fixed;
	cursor: pointer;
}

.menu--button + .menu--button--label > * {
	display: block;
	overflow: hidden;
}

.menu--button + .menu--button--label .open-text {
	max-width: 0;
	will-change: max-width;
}

.menu--button:not(:checked) + .menu--button--label .closed-text {
	max-width: 10rem;
	transition: max-width 0.05s linear 1.2s;
}

.menu--button:checked + .menu--button--label .open-text {
	max-width: 10rem;
	transition: max-width 0.05s linear 1.2s;
}

.menu--button:checked + .menu--button--label .closed-text {
	max-width: 0;
}

.menu {
	will-change: right;
	transition: right 0.8s ease-in-out;
}

.menu--button:checked ~ .menu {
	right: 0;
}
