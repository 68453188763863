:root {
	--h-header: 2.5rem;
	--p-project: 1rem;
	--p-project-half: .5rem;
	--h-project-info: 2.4rem;
	--h-project-info-open: 9.6rem;
	--ratio-poster: 0.8;
	--w-poster: calc(((100vh - var(--h-header) - var(--h-project-info) - 2 * var(--p-project)) * var(--ratio-poster)) + var(--p-project));
}

@screen lg {
	:root {
		--h-header: 3rem;
	}
}
