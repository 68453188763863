@media (orientation: landscape) {
	.swiper-container-projects {
		position: relative;
		width: 100%;
	}

	.swiper-container-projects .swiper-slide {
		position: relative;
		box-sizing: border-box;
		width: var(--w-poster);
		height: calc(100vh - var(--h-header));
		padding: 0 var(--p-project-half) calc(2 * var(--p-project) + var(--h-project-info));
	}

	.swiper-container-projects .swiper-slide .project-image {
		position: absolute;
		top: 0;
		width: calc(var(--w-poster) - var(--p-project));
		height: calc(100vh - var(--h-header) - var(--h-project-info) - 2 * var(--p-project));
		object-fit: cover;
		object-position: center;
	}

	.swiper-container-projects .swiper-slide .project-image:last-child {
		position: relative;
		z-index: 10;
	}

	.swiper-container-projects .project-info {
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.swiper-container-projects .swiper-button {
		position: absolute;
		top: 0;
		left: 0;
		width: calc(50% - ((100vh - var(--h-header) - var(--h-project-info) - var(--p-project)) * var(--ratio-poster) * .5) - var(--p-project-half));
		height: 100%;
		margin: 0;
		cursor: url("../images/arrow-w.svg") 24 16, w-resize;
	}

	.swiper-container-projects .swiper-button-next {
		left: auto;
		right: 0;
		cursor: url("../images/arrow-e.svg") 24 16, e-resize;
	}

	.swiper-container-projects .swiper-button::after,
	.swiper-container-projects .swiper-button-next::after {
		content: '';
	}
}

@media (orientation: portrait) {
	.project-image + .project-image {
		position: absolute;
		top: 0;
		width: 100%;
		height: auto;
	}
}
