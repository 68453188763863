/* tailwind */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* vendor */
@import "swiper/swiper-bundle.css";

/* custom */
@import "modules/settings.css";
@import "modules/typography.css";
@import "modules/header.css";
@import "modules/menu.css";
@import "modules/project.details.css";
@import "modules/project.info.css";
@import "modules/project.carousel.css";

body {
	@apply relative overflow-x-hidden w-screen;
}

@screen landscape {
	body {
		@apply overflow-y-hidden;
	}
}
