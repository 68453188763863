.project-details-viewer {
	position: absolute;
	top: 101vh;
	z-index: 99;
	width: 100%;
	overflow: hidden;
	background: white;
	transition: top .2s ease-in-out;
}

.project-details-viewer * {
	scrollbar-width: none;
}

/* hide scrollbars */
.project-details-viewer *::-webkit-scrollbar {
  display: none;
}

.project-details-viewer.is-open {
	top: 0;
}

.project-details-button {
	height: var(--h-header);
	@apply absolute top-0 right-0 p-2;
}

.project-details-images {
	height: calc(100vh - var(--h-header) - 2 * var(--p-project));
	overflow: auto;
}

@media (max-width: 767px) {
	.project-image-wrapper {
		max-width: 100% !important;
	}
}

@screen md {
	.project-details-button {
		@apply px-4;
	}
}
