html {
	font-size: 14px;
}

body {
	@apply leading-normal;
}

.kt h2:not(:first-child),
.kt h3:not(:first-child),
.kt h4:not(:first-child),
.kt h5:not(:first-child),
.kt h6:not(:first-child),
.kt p:not(:first-child),
.kt ul:not(:first-child),
.kt ol:not(:first-child) {
	margin-top: 1rem;
}

@screen md {
	html {
		font-size: 16px;
	}
}
